import pic1 from '../../pics/forGame/1.jpg';
import pic2 from '../../pics/forGame/2.jpg';
import pic3 from '../../pics/forGame/3.jpg';
import pic4 from '../../pics/forGame/4.jpg';
import pic5 from '../../pics/forGame/5.jpg';
import pic6 from '../../pics/forGame/6.jpg';
import pic7 from '../../pics/forGame/7.jpg';
import pic8 from '../../pics/forGame/8.jpg';
import pic9 from '../../pics/forGame/9.jpg';
import pic10 from '../../pics/forGame/10.jpg';
import pic11 from '../../pics/forGame/11.jpg';
import pic12 from '../../pics/forGame/12.jpg';
import pic13 from '../../pics/forGame/13.jpg';
import pic14 from '../../pics/forGame/14.jpg';
import pic15 from '../../pics/forGame/15.jpg';
import pic16 from '../../pics/forGame/16.jpg';
import pic17 from '../../pics/forGame/17.jpg';
import pic18 from '../../pics/forGame/18.jpg';
import pic19 from '../../pics/forGame/19.jpg';
import pic20 from '../../pics/forGame/20.jpg';
import pic21 from '../../pics/forGame/21.jpg';
import pic22 from '../../pics/forGame/22.jpg';
import pic23 from '../../pics/forGame/23.jpg';
import pic24 from '../../pics/forGame/24.jpg';
import pic25 from '../../pics/forGame/25.jpg';
import pic26 from '../../pics/forGame/26.jpg';
import pic27 from '../../pics/forGame/27.jpg';
import pic28 from '../../pics/forGame/28.jpg';
import pic29 from '../../pics/forGame/29.jpg';
import pic30 from '../../pics/forGame/30.jpg';
import pic31 from '../../pics/forGame/31.jpg';
import pic32 from '../../pics/forGame/32.jpg';
import pic33 from '../../pics/forGame/33.jpg';
import pic34 from '../../pics/forGame/34.jpg';
import pic35 from '../../pics/forGame/35.jpg';
import pic36 from '../../pics/forGame/36.jpg';
import pic37 from '../../pics/forGame/37.jpg';
import pic38 from '../../pics/forGame/38.jpg';
import pic39 from '../../pics/forGame/39.jpg';
import pic40 from '../../pics/forGame/40.jpg';
import pic41 from '../../pics/forGame/41.jpg';
import pic42 from '../../pics/forGame/42.jpg';
import pic43 from '../../pics/forGame/43.jpg';
import pic44 from '../../pics/forGame/44.jpg';
import pic45 from '../../pics/forGame/45.jpg';

const pics = [
    {
        photo:pic1
    },
    {
        photo:pic2
    },
    {
        photo:pic3
    },
    {
        photo:pic4
    },
    {
        photo:pic5
    },
    {
        photo:pic6
    },
    {
        photo:pic7
    },
    {
        photo:pic8
    },
    {
        photo:pic9
    },
    {
        photo:pic10
    },
    {
        photo:pic11
    },
    {
        photo:pic12
    },
    {
        photo:pic13
    },
    {
        photo:pic14
    },
    {
        photo:pic15
    },
    {
        photo:pic16
    },
    {
        photo:pic17
    },
    {
        photo:pic18
    },
    {
        photo:pic19
    },
    {
        photo:pic20
    },
    {
        photo:pic21
    },
    {
        photo:pic22
    },
    {
        photo:pic23
    },
    {
        photo:pic24
    },
    {
        photo:pic25
    },
    {
        photo:pic26
    },
    {
        photo:pic27
    },
    {
        photo:pic28
    },
    {
        photo:pic29
    },
    {
        photo:pic30
    },
    {
        photo:pic31
    },
    {
        photo:pic32
    },
    {
        photo:pic33
    },
    {
        photo:pic34
    },
    {
        photo:pic35
    },
    {
        photo:pic36
    },
    {
        photo:pic37
    },
    {
        photo:pic38
    },
    {
        photo:pic39
    },
    {
        photo:pic40
    },
    {
        photo:pic41
    },
    {
        photo:pic42
    },
    {
        photo:pic43
    },
    {
        photo:pic44
    },
    {
        photo:pic45
    }
]

export {pics}