import pic1 from '../pics/me/1.jpg';
import pic2 from '../pics/me/2.jpg';
import pic3 from '../pics/me/3.jpg';
import pic4 from '../pics/me/4.jpg';
import pic5 from '../pics/me/5.jpg';
import pic6 from '../pics/me/6.jpg';
import pic7 from '../pics/me/7.jpg';
import pic8 from '../pics/me/8.jpg';
import pic9 from '../pics/me/9.jpg';
import pic10 from '../pics/me/10.jpg';
import pic11 from '../pics/me/11.jpg';
import pic12 from '../pics/me/12.jpg';
import pic13 from '../pics/me/13.jpg';
import pic14 from '../pics/me/14.jpg';
import pic15 from '../pics/me/15.jpg';
import pic16 from '../pics/me/16.jpg';
import pic17 from '../pics/me/17.jpg';
import pic18 from '../pics/me/18.jpg';
import pic19 from '../pics/me/19.jpg';
import pic20 from '../pics/me/20.jpg';
import pic21 from '../pics/me/21.jpg';
import pic22 from '../pics/me/22.jpg';
import pic23 from '../pics/me/23.jpg';
import pic24 from '../pics/me/24.jpg';
import pic25 from '../pics/me/25.jpg';
import pic26 from '../pics/me/26.jpg';
import pic27 from '../pics/me/27.jpg';
import pic28 from '../pics/me/28.jpg';
import pic29 from '../pics/me/29.jpg';
import pic30 from '../pics/me/30.png';

const imagesForHome = [
    {
        photo:pic1
    },
    {
        photo:pic2
    },
    {
        photo:pic3
    },
    {
        photo:pic4
    },
    {
        photo:pic5
    },
    {
        photo:pic6
    },
    {
        photo:pic7
    },
    {
        photo:pic8
    },
    {
        photo:pic9
    },
    {
        photo:pic10
    },
    {
        photo:pic11
    },
    {
        photo:pic12
    },
    {
        photo:pic13
    },
    {
        photo:pic14
    },
    {
        photo:pic15
    },
    {
        photo:pic16
    },
    {
        photo:pic17
    },
    {
        photo:pic18
    },
    {
        photo:pic19
    },
    {
        photo:pic20
    },
    {
        photo:pic21
    },
    {
        photo:pic22
    },
    {
        photo:pic23
    },
    {
        photo:pic24
    },
    {
        photo:pic25
    },
    {
        photo:pic26
    },
    {
        photo:pic27
    },
    {
        photo:pic28
    },
    {
        photo:pic29
    },
    {
        photo:pic30
    }
]

export {imagesForHome}